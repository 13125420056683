// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ProductBenefitsComposer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ProductBenefitsComposer.tsx");
  import.meta.hot.lastModified = "1702938542807.599";
}
// REMIX HMR END

import clsx from "clsx";
import { useRef, useState } from "react";
import { useCopy } from "~/hooks/useCopy";
/**
 * Component
 */
export const Product_Benefits_Composer = ({
  ...props
}) => {
  _s();
  // 115 <--- max longitud
  const [product_benefits, set_product_benefits] = useState("");
  const is_description_field_touch = useRef(false);
  //
  const SIZE_MAX = 115;
  const size = new Blob([product_benefits]).size;
  const is_under_size_limit = size <= 115;
  //
  const {
    copy,
    copy_state
  } = useCopy();
  function on_copy_click() {
    copy(product_benefits);
  }
  return <div className='grid gap-8'>
            <div className='grid gap-4'>
                <label htmlFor='keywords' className='text-gray-700'>
                    <span>Compose your products benefits here: </span>
                    <span className={clsx(is_under_size_limit ? "text-green-600" : "text-red-600")}>
                        Size: {size}, max {SIZE_MAX}
                    </span>
                </label>
                <textarea className='w-full border border-gray-700 rounded-md shadow-md shadow-gray-300 p-4 ' id='description' rows={10} onChange={evt => {
        if (!is_description_field_touch.current) {
          is_description_field_touch.current = true;
        }
        set_product_benefits(evt.target.value);
      }}></textarea>
                \
            </div>
            <button onClick={on_copy_click} className={clsx("bg-black text-white text-lg py-2 rounded-md shadow-sm ", copy_state == "copied" ? "bg-green-600" : "")}>
                <span>{copy_state == "copied" ? "Copied" : "Copy"}</span>
            </button>
        </div>;
};
_s(Product_Benefits_Composer, "1BOnohF98rlSy1CbuOs9cLUAULY=", false, function () {
  return [useCopy];
});
_c = Product_Benefits_Composer;
var _c;
$RefreshReg$(_c, "Product_Benefits_Composer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;