// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/TitleComposer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/TitleComposer.tsx");
  import.meta.hot.lastModified = "1703018479317.1165";
}
// REMIX HMR END

import clsx from "clsx";
import { useRef, useState } from "react";
import { useCopy } from "~/hooks/useCopy";
/**
 * Component
 */
export const Title_Composer = ({
  ...props
}) => {
  _s();
  const [title, set_title] = useState("");
  const is_description_field_touch = useRef(false);
  //
  const size = new Blob([title]).size;
  const MAX_SIZE = 200;
  const is_title_under_size_limit = size <= MAX_SIZE;
  const would_title_be_chunked_on_mobile = size > 80;
  //
  const {
    copy,
    copy_state
  } = useCopy();
  function on_copy_click() {
    copy(title);
  }
  return <div className='grid gap-8'>
            <div className='grid gap-4'>
                <label htmlFor='keywords' className='text-gray-700'>
                    <span>Compose your title here: </span>
                    <span className={clsx(is_title_under_size_limit ? "text-green-600" : "text-red-600")}>
                        Size: {size}, max {MAX_SIZE}
                    </span>
                </label>
                <textarea className='w-full border border-gray-700 rounded-md shadow-md shadow-gray-300 p-4 ' id='description' rows={10} onChange={evt => {
        if (!is_description_field_touch.current) {
          is_description_field_touch.current = true;
        }
        set_title(evt.target.value);
      }}></textarea>
                {would_title_be_chunked_on_mobile ? <p className='text-amber-500'>
                        A title Longer then 80 characters, like this one, would be chunked on mobile
                        view
                    </p> : null}
            </div>
            <button onClick={on_copy_click} className={clsx("bg-black text-white text-lg py-2 rounded-md shadow-sm ", copy_state == "copied" ? "bg-green-600" : "")}>
                <span>{copy_state == "copied" ? "Copied" : "Copy"}</span>
            </button>
        </div>;
};
_s(Title_Composer, "3jUoitP+XLxCCUUkTa9X33IcyZE=", false, function () {
  return [useCopy];
});
_c = Title_Composer;
var _c;
$RefreshReg$(_c, "Title_Composer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;