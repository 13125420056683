// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index/route.tsx");
  import.meta.hot.lastModified = "1705526673524.177";
}
// REMIX HMR END

import { json } from "@remix-run/cloudflare";
import { DescriptionComposer } from "~/components/DescriptionComposer";
import { DirectionsComposer } from "~/components/DirectionsComposer";
import { KeyProductFeatureComposer } from "~/components/KeyProductFeatureComposer";
import { Product_Benefits_Composer } from "~/components/ProductBenefitsComposer";
import { SearchTermsComposer } from "~/components/SearchTermsComposer";
import { Title_Composer } from "~/components/TitleComposer";
export const meta = () => {
  return [{
    title: "Amazon product listing tools"
  }, {
    name: "description",
    content: "Compose your product listings fields with ease"
  }];
};
export async function loader({
  request,
  context
}) {
  return json({});
}
export default function Index() {
  return <div className='min-h-screen w-full py-20'>
            <div className='max-w-screen-lg px-8 mx-auto grid gap-14'>
                <Menu />
                <Title_Composer />
                <KeyProductFeatureComposer />
                <Product_Benefits_Composer />
                <SearchTermsSection />
                <DescriptionSection />
                <DirectionsSection />
            </div>
        </div>;
}
_c = Index;
function Menu() {
  return <div className='grid gap-4'>
            <div className=''>
                <h1 className='text-4xl'>Amazon Seller Tools</h1>
            </div>
            <div className=''>
                <ul className='flex items-center justify-start gap-8'>
                    <li>
                        <a href='#description-composer' className='text-blue-600 '>
                            Description composer
                        </a>
                    </li>
                    <li>
                        <a href='#search-terms-composer' className='text-blue-600 '>
                            Search terms composer
                        </a>
                    </li>
                </ul>
            </div>
        </div>;
}
_c2 = Menu;
function SearchTermsSection() {
  return <section className='grid gap-8'>
            <div className='grid gap-4'>
                <h2 id='search-terms-composer' className='text-2xl'>
                    Search Terms Composer
                </h2>
            </div>
            <SearchTermsComposer />
            <Search_Terms_Resources />
        </section>;
}
_c3 = SearchTermsSection;
function Search_Terms_Resources() {
  return <div className='grid gap-4'>
            <h3 className='text-xl'>Resources</h3>
            <p>
                To learn more about search terms see the field{" "}
                <a className='text-blue-600' href='https://sellercentral.amazon.com/help/hub/reference/F2C2L6RCFZGWBXC'>
                    limits
                </a>{" "}
                and how to{" "}
                <a href='https://sellercentral.amazon.com/help/hub/reference/G23501' className='text-blue-600'>
                    use it effectively
                </a>
                .
            </p>
            <p>Tips for drafting and optimizing your search terms include the following:</p>

            <ul className=' list-disc pl-4'>
                <li>Maintain the text length limit of search terms.</li>
                <li>Make use of synonyms.</li>
                <li>Use spelling variations, but don’t include common misspellings.</li>
                <li>Include abbreviations.</li>
                <li>Use all lowercase letters.</li>
                <li>Avoid punctuation marks such as semicolons, colons, and dashes.</li>
                <li>Separate words with spaces.</li>
                <li>Avoid repetitions.</li>
                <li>
                    Avoid articles, prepositions, or other short words such as “a,” “an,” “and,”
                    “by,” “for,” “of,” “the,” or “with.”
                </li>
                <li>Use either singular or plural words.</li>
            </ul>
            <p>
                Extracted from Amazon Seller Search Terms{" "}
                <a href='https://sellercentral.amazon.com/help/hub/reference/G23501' className='text-blue-600'>
                    Reference
                </a>
                .
            </p>
        </div>;
}
_c4 = Search_Terms_Resources;
function DescriptionSection() {
  return <section className='grid gap-8'>
            <div className='grid gap-4'>
                <h2 id='description-composer' className='text-2xl'>
                    Description Composer
                </h2>
            </div>
            <DescriptionComposer />
            {/* <Description_Resources /> */}
        </section>;
}
_c5 = DescriptionSection;
function DirectionsSection() {
  return <section className='grid gap-8'>
            <div className='grid gap-4'>
                <h2 id='description-composer' className='text-2xl'>
                    Description Composer
                </h2>
            </div>
            <DirectionsComposer />
            {/* <Description_Resources /> */}
        </section>;
}
_c6 = DirectionsSection;
var _c, _c2, _c3, _c4, _c5, _c6;
$RefreshReg$(_c, "Index");
$RefreshReg$(_c2, "Menu");
$RefreshReg$(_c3, "SearchTermsSection");
$RefreshReg$(_c4, "Search_Terms_Resources");
$RefreshReg$(_c5, "DescriptionSection");
$RefreshReg$(_c6, "DirectionsSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;